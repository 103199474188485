import {
  SummaryFixedFacilities,
  SummaryFixedSingleTank,
  SummaryFixedItem,
  SummaryFixedFlow,
} from "@/types";

// 【サマリー版画面表示用】設備データの固定部分のみをまとめたものを返す
export const getSummaryFixedFacilities = function () {
  return {
    adjustment: {
      title: "調整槽",
      icon: "adjustmentTankIcon",
      items: [
        {
          display_name_en: "data_water_level_in_regulating_tank",
          text: "水位",
          icon: "mdi-hydraulic-oil-level",
          default_data_type: "sensor",
        },
        {
          display_name_en: "data_regulating_tank_potential_hydrogdata",
          text: "pH",
          icon: "mdi-hexagon-multiple-outline",
          default_data_type: "sensor",
        },
      ] as Array<SummaryFixedItem>,
    } as SummaryFixedSingleTank,
    fermentation: {
      title: "発酵槽",
      icon: "fermentationTankIcon",
      items: [
        {
          display_name_en: "data_fermentation_tank_water_level",
          text: "水位",
          icon: "mdi-hydraulic-oil-level",
          default_data_type: "sensor",
        },
        {
          display_name_en: "data_fermentation_tank_potential_hydrogdata",
          text: "pH",
          icon: "mdi-hexagon-multiple-outline",
          default_data_type: "sensor",
        },
        {
          display_name_en: "data_oxidation_reduction_potential",
          text: "ORP",
          icon: "mdi-resistor",
          default_data_type: "sensor",
        },
        {
          display_name_en: "data_fermentation_tank_temperature",
          text: "温度",
          icon: "mdi-thermometer-lines",
          default_data_type: "sensor",
        },
        {
          display_name_en: "data_fermentation_tank_pressure",
          text: "圧力",
          icon: "mdi-arrow-collapse-vertical",
          default_data_type: "sensor",
        },
        {
          display_name_en: "operation_fermentation_tank_agitator",
          text: "撹拌機",
          icon: "mdi-crop-rotate",
          default_data_type: "operation",
        },
      ] as Array<SummaryFixedItem>,
    } as SummaryFixedSingleTank,
    measurement: {
      title: "計量槽",
      icon: "weighingTankIcon",
      items: [
        {
          display_name_en: "data_ammonia_nitrogen",
          text: "アンモニア<br/>態窒素",
          icon: "mdi-water-outline",
          default_data_type: "sensor",
        },
        {
          display_name_en: "data_mixed_liquor_suspended_solids",
          text: "MLSS",
          icon: "mdi-beaker-outline",
          default_data_type: "sensor",
        },
      ] as Array<SummaryFixedItem>,
    } as SummaryFixedSingleTank,
    digestiveJuice: {
      title: "消化液貯留槽",
      icon: "dilutedLiquidDischargeTankIcon",
      items: [
        {
          display_name_en:
            "operation_digested_liquid_storage_tank_level_switch",
          text: "水位レベル<br/>スイッチ",
          icon: "mdi-waves-arrow-up",
          default_data_type: "operation",
        },
        {
          display_name_en: "operation_digested_liquid_storage_tank_agitator",
          text: "撹拌機",
          icon: "mdi-crop-rotate",
          default_data_type: "operation",
        },
      ] as Array<SummaryFixedItem>,
    } as SummaryFixedSingleTank,
    hotWater: {
      title: "温水槽",
      icon: "hotWaterTankIcon",
      items: [
        {
          display_name_en: "data_hot_water_tank_temperature",
          text: "温度",
          icon: "mdi-thermometer-lines",
          default_data_type: "sensor",
        },
        {
          display_name_en: "operation_water_heater_hot_water_pump",
          text: "給水器<br/>温水ポンプ",
          icon: "mdi-water-pump",
          default_data_type: "operation",
        },
        {
          display_name_en: "operation_hot_water_heater",
          text: "温水ヒーター",
          icon: "mdi-radiator",
          default_data_type: "operation",
        },
        {
          display_name_en: "operation_hot_water_circulation_pump",
          text: "温水循環<br/>ポンプ",
          icon: "mdi-water-pump",
          default_data_type: "operation",
        },
        {
          display_name_en: "operation_water_heater_area_gas_leak",
          text: "ガス漏れ",
          icon: "mdi-fire-alert",
          default_data_type: "operation",
        },
      ] as Array<SummaryFixedItem>,
    } as SummaryFixedSingleTank,
    dilutedSolution: {
      title: "希釈液放流槽",
      icon: "dilutedLiquidDischargeTankIcon",
      items: [
        {
          display_name_en: "operation_liquid_release_tank_level_switch",
          text: "水位レベル<br/>スイッチ",
          icon: "mdi-waves-arrow-up",
          default_data_type: "operation",
        },
        {
          display_name_en: "operation_liquid_release_tank_agitator",
          text: "撹拌機",
          icon: "mdi-crop-rotate",
          default_data_type: "operation",
        },
      ] as Array<SummaryFixedItem>,
    } as SummaryFixedSingleTank,
    gasPack: {
      title: "ガスパック",
      icon: "gasPackIcon",
      items: [
        {
          display_name_en: "data_gas_concentration",
          text: "ガス濃度",
          icon: "mdi-weather-windy",
          default_data_type: "sensor",
        },
        {
          display_name_en: "data_gas_holder_height",
          text: "ガスホルダー高さ",
          icon: "mdi-waves-arrow-up",
          default_data_type: "sensor",
        },
        {
          display_name_en: "data_gas_emergency_emission",
          text: "ガス緊急</br>排出量",
          icon: "mdi-weather-windy-variant",
          default_data_type: "sensor",
        },
        {
          display_name_en: "operation_gas_holder_emergency_discharge_pump",
          text: "緊急排出<br/>ポンプ",
          icon: "mdi-water-pump",
          default_data_type: "operation",
        },
        {
          display_name_en: "operation_gas_holder_room_gas_leak",
          text: "ガス漏れ",
          icon: "mdi-fire-alert",
          default_data_type: "operation",
        },
      ] as Array<SummaryFixedItem>,
    } as SummaryFixedSingleTank,
    airPump: {
      title: "エアポンプ",
      icon: "airPumpIconIcon",
      items: [
        {
          display_name_en: "data_gas_generation",
          text: "ガス発生量",
          icon: "mdi-truck-cargo-container",
          default_data_type: "sensor",
        },
      ] as Array<SummaryFixedItem>,
    } as SummaryFixedSingleTank,
    gasTank: {
      title: "ガスタンク",
      icon: "gasTankIcon",
      items: [
        {
          display_name_en: "data_gas_tank_pressure",
          text: "圧力",
          icon: "mdi-arrow-collapse-vertical",
          default_data_type: "sensor",
        },
      ] as Array<SummaryFixedItem>,
    } as SummaryFixedSingleTank,
  } as SummaryFixedFacilities;
};

// 【サマリー版画面表示用】Flowデータの固定部分のみをまとめたものを返す
export const getSummaryFixedFlows = function () {
  return [
    {
      display_name_en: "data_raw_material_input",
      borderColor: "#a18674",
      arrowColor: "#815e46",
      backgroundColor: "#f0ebe8",
      arrowDirection: "down",
      icon: "mdi-trash-can-outline",
      iconColor: "#623618",
      message: "原料投入量",
    },
    {
      display_name_en: "data_fermentation_tank_ingredient_input_amount",
      borderColor: "#a18674",
      arrowColor: "#815e46",
      backgroundColor: "#f0ebe8",
      arrowDirection: "right",
      icon: "mdi-water-outline",
      message: "原料投入量",
    },
    {
      display_name_en: "data_measuring_tank_ingredient_input_amount",
      borderColor: "#a18674",
      arrowColor: "#815e46",
      backgroundColor: "#f0ebe8",
      arrowDirection: "right",
      icon: "mdi-water-outline",
      message: "原料投入量",
    },
    {
      display_name_en: "data_gas_consumption_by_water_heaters",
      borderColor: "#a18674",
      arrowColor: "#815e46",
      backgroundColor: "#f0ebe8",
      arrowDirection: "no",
      icon: "mdi-truck-cargo-container",
      iconColor: "#58AC31",
      message: "給湯器使用ガス量",
    },
  ] as Array<SummaryFixedFlow>;
};
